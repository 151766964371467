<template>
  <div class="content">
    <img src="@/image/index.jpg" alt="">
  </div>
</template>
<script>
export default {
  mounted() {
    setTimeout(() => {
      if (this.$route.query.p) {
        this.$router.push({path:'/home',replace: true, query:{p: this.$route.query.p} })
      } else {
        this.$router.replace('home')
      }
    },3000)
  },
}
</script>

<style lang="less" scoped>
  .content{
    min-height: 100vh;
    background: #0b4cfc;
    img{
      width: 100%;
    }
  }
</style>